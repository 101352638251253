.faculty-slide{
    display: flex;
    width: 40%;
    margin: 2rem 1.5rem;
}


.faculty-slide__intro{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: fit-content;
    flex-wrap: wrap;
}

.faculty-slide__text{
    margin-top: 3rem;
    margin-left:1rem;
    width: fit-content;
}

.faculty-slide img{
    align-self: center;
    max-height: fit-content;
    width: 150px;
    min-height: 180px;
    object-fit:fill;
    box-shadow: 0px 0px 2px 1px var(--color-palette3);
}

.faculty-slide__name{
    margin: 2px 0;
}

.faculty-slide__subject{
    margin: 2px 0;
    margin-bottom: .5rem;
}

.faculty-slide__textCn{
    flex-wrap: wrap;
}

.faculty-slide__textEn{
    flex-wrap: wrap;
}

@media screen and (max-width: 1150px) {
    .faculty-slide{
        flex-direction: column;
        align-items: center;
        width: 80%;}
    .faculty-slide__text{margin-top: 1rem;}
}